.line-legend {
  ul {
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin-left: 10px;

      span {
        width: 20px;
        height: 3px;
        border-radius: 0px;
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}
.clickable {
  display:block;
}
.marginTop16 {
  margin-top: 16px;
}
.marginTop8 {
  margin-top: 8px;
}
.rectangle1 {
  display: inline-block;
  width: 150px;
  height: 100px;
  background: rgb(231, 170, 0);
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.rectangle2 {
  display: inline-block;
  width: 150px;
  height: 100px;
  background: rgb(82, 189, 135);
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded-legend {
  ul {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;

    li {
      font-weight: 500;
      margin-right: 10px;

      span {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
        font-size: 0.875rem;
      }
    }
  }
}

.average-price-card {
  .card {
    background: $card-accent-color;
  }

  h3 {
    margin-bottom: 0;
  }


  .icon-holder {
    border-radius: 5px;
    border: 1px solid $iconholder-primary-border-color;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $iconholder-primary-bg;
    color: $iconholder-primary-color;
    font-size: 20px;

    i {
      line-height: 1;
    }
  }
}

#dashboard-vmap {
  height: 161px;
  width: 100%;

  .jvectormap-zoomin,
  .jvectormap-zoomout {
    display: none;
  }
}

.map-height {
  height: 160px;
}

.add-btn {
  background: rgb(170, 4, 4);
}

.proBanner {
  .purchase-popup {
    @extend .grid-margin;
    background: #fff;
    padding: 15px 20px;
    @include border-radius(3px);

    .btn {
      margin-right: 20px;
      font-weight: 500;
      color: $white;
      @include border-radius(5px);
      @include transition-duration(0.2s);

      &.download-button {
        background: rgba(249, 249, 249, 0.7);
        color: #969292;
        border: 1px solid darken(#e4e4e4, 5%);
      }

      &.purchase-button {
        background-color: theme-color(info);
        color: $white;
        border: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    p {
      margin-bottom: auto;
      margin-top: auto;
      color: darken(#e4e4e4, 40%);
      font-weight: 400;
      vertical-align: middle;
      line-height: 1;
    }

    i {
      vertical-align: middle;
      line-height: 1;
      margin: auto 0;
      color: darken(#e4e4e4, 20%);
    }

    .bannerClose {
      cursor: pointer;
    }
  }

  &.hide {
    display: none;
  }
}